import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab &bull; Research" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            {/* <span className="image main"><img src={pic04} alt="" /></span> */}
            <h2>Research</h2>
            <p>
              The laboratory specialises in computational biology, functional
              genomics, and non-invasive diagnostics. With a focus on the
              computational and statistical analyses of the RNA, protein, and
              metabolites produced by healthy and unhealthy tissues/cell-types
              we define markers of various diseases that can be detected in
              blood, urine, and cerebrospinal fluid. We apply state-of-the-art
              statistical/machine-learning approaches and leverage the massive
              power and flexibility of cloud-computing to define novel
              biomarkers of cardiovascular disease, type 2 diabetes,
              neurodegenerative diseases, and various cancers. Recently the lab
              have become active contributors to Mass-General Brigham’s COVID-19
              Innovation effort, helping to evaluate low-cost serologic
              (antibody/immunity) and diagnostic (viral RNA/protein detection)
              technologies before they are deployed to the greater Boston area
              and beyond.
            </p>
            {/* <h3>
              extracellular RNA
            </h3>
            <h3>
              cardiology
            </h3>
            <h3>
              neurology
            </h3> */}
          </section>

          <section id="content2" className="main">
            <h2>Publications</h2>
            <p>
              A complete list of publications is maintained at the{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://connects.catalyst.harvard.edu/Profiles/display/Person/184153"
              >
                Harvard Catalyst
              </a>
              , as well as{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://pubmed.ncbi.nlm.nih.gov/?term=Robert%20Kitchen%5BFull%20Author%20Name%5D%20OR%20Rob%20Kitchen%5BFull%20Author%20Name%5D&sort=pubdate&size=20"
              >
                PubMed
              </a>
              , and{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://scholar.google.com/citations?user=Fnrm6iwAAAAJ&hl=en"
              >
                Google Scholar
              </a>
              .
            </p>
            <iframe
              src="https://connects.catalyst.harvard.edu/Profiles/display/Person/184153"
              style={{ height: '1000px', width: '100%', display: 'block' }}
              title="Harvard Catalyst Profile"
            ></iframe>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
